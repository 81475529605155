<template>
  <div style="height: inherit">

    <!-- Overlay -->
    <div class="body-content-overlay"/>

    <!-- Searchbar -->
    <div class="course-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
                v-model="search"
                placeholder="Поиск курсов"
                class="search-product"
                @keyup="fetchShopProducts"
            />
            <b-input-group-append is-text>
              <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <!-- Prodcuts -->
    <section class="mt-2">
      <b-row class="courses-student">
        <b-col sm="12" md="6" lg="3" :col="true"
               v-for="course in courses"
               :key="course.id"
               class="mb-1"
        >

            <b-card
                class="box-shadow-1 h-100"
                :img-src="course.image ? `https://edu.startupchoikhona.tj/backend/${course.image}` : require('@/assets/images/blogs/noPhotoBlogs.jpeg')"
                img-alt=""
                img-top
            >
              <b-link :to="`/course-details/${course.id}/fullscreen`"><span class="play-course"><h3 class="w-100 h-100 text-white bold">Начать</h3></span></b-link>
              <b-link :to="{ name: 'course-details', params: { id: course.id } }">
                <b-card-text class="h3 font-weight-bold pt-1">
                  {{course.title}}
                </b-card-text>
              <b-card-text>
                <small>
                  <span>Ментор: </span>
                  <span>{{ course.teacher.name }}</span>
                </small>
              </b-card-text>
              <b-card-text class="d-flex align-items-center">
            </span>

                <span>
              <ul class="unstyled-list list-inline">
                <li
                    v-for="star in 5"
                    :key="star"
                    class="ratings-list-item"
                    :class="{'ml-25': star-1}"
                >
                  <feather-icon
                      icon="StarIcon"
                      size="16"
                      :class="[{'fill-current': star <= course.rating}, star <= course.rating ? 'text-warning' : 'text-muted']"
                  />
                </li>
              </ul>
            </span>
              </b-card-text>
              </b-link>
            </b-card>
        </b-col>
      </b-row>

    </section>
    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
              v-model="page"
              :total-rows="totalProducts"
              :per-page="perPage"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import '@/assets/css/styleCoursesStudent.css'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    router,
  },
  data() {
    return {
      page: 1,
      perPage: 15,
      search: '',
      courses: [],
      totalProducts: 0,
    }
  },
  created() {
    this.fetchShopProducts()
  },
  methods: {
    view(id) {
      this.$router.push({
        name: 'course-details',
        params: { id }
      })
    },
    fetchShopProducts() {
      this.$http.post('https://edu.startupchoikhona.tj/backend/api/student/course/all', {
        search: this.search,
      })
        .then(response => {
          const { data } = response.data
          this.courses = data.items
          this.totalProducts = data.total_rows
        })
    },
  },
  setup() {

    return {
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-course.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.courses-student .play-course {
  cursor: pointer;
  clear: both;
  margin: auto;
  border-radius: 10%;
  box-shadow: 0 3px 0 #259cff, 0 3px 10px rgb(11 131 229), 0 0px 10px rgb(10 134 236), 0 0px 4px rgb(0 0 0 / 35%), 0 5px 20px rgb(9 121 214), 0 15px 40px rgb(49 140 215), inset 0 0 15px rgb(255 255 255 / 5%);
}

.courses-student .play-course {
  width: 134px;
  height: 46px;
  position: absolute;
  display: block;
  top: -32px;
  overflow: hidden;
}

.courses-student .play-course h3 {
  left: 21%;
  top: 24%;
  position: relative;
}

.courses-student .play-course:after {
  background-image: unset;
}
</style>
